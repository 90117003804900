import React, { useEffect, useState } from 'react';
import { Row, Col, Form, Button } from 'react-bootstrap';
import { useRecoilValue } from 'recoil';
import { folderState } from '../Recoil/atoms';
import { Pencil } from 'lucide-react';


const ItemDay = ({ blogView, item, index, folderid, changeItemDay, followView }) => {
    const [showDayInput, setShowDayInput] = useState(false);
    const [day, setDay] = useState('');
    const folders = useRecoilValue(folderState);

    const handleSubmitDay = (e) => {
        e.preventDefault();
        // console.log(day);
        setShowDayInput(false);
        changeItemDay(index, day, folderid);
    };

    useEffect(() => {
        if (item.day != day) {
            setDay(() => !!item.day ? item.day : '');
        }
    }, [folders])


    const isDayValid = (day) => {
        if (!day) {
            return false;
        }

        if (Object.keys(day).length === 0) {
            return false;
        }
        
        return true;
    }

    return (
        <>
        {!!blogView && !showDayInput && (
            <Row>
                <Col className='left-align'>
                    {isDayValid(item.day) ? <h5 className='mb-0 text-dark'>Day {item.day}</h5> : <><h5 className='mb-0 d-inline text-dark'>Day: </h5><p className='mb-1 d-inline'>None Set</p></>}
                </Col>
                <Col className='right-align'>
                    <Button size="sm" className='btn-transparent mb-1' onClick={() => setShowDayInput(true)}><Pencil color='#424242' size={20} /></Button>
                </Col>
            </Row>
        )}
        {!!blogView && !!showDayInput && (
            <Row>
                <Col>
                    <Form onSubmit={handleSubmitDay}>
                        <Form.Group as={Row} className='text-left border-top mt-0 mb-1'>
                            <Col className='mt-3 col-12 left-align d-inline pr-0 mr-0'>
                                <Form.Label className='d-inline'><h5 className='m-0 d-inline'>Day:</h5></Form.Label>
                                <Form.Control className='ml-1 d-inline' style={{ width: "30%" }} type="number" placeholder='Day No.' value={day} onChange={(e) => setDay(e.target.value)} id='dayInput' />
                                <Button className='d-inline btn-primary ml-1 pr-2 pl-2 pt-1 pb-1 d-inline ml-0 mb-1' type="submit" size="md">Save</Button>
                            </Col>
                        </Form.Group>
                    </Form>
                </Col>
            </Row>
        )}
        {!!followView && isDayValid(item.day) && (
            <Row>
                <Col className='left-align'>
                    <h5 className='mb-0 text-dark'>Day {item.day}</h5>
                </Col>
            </Row>
        )}
        </>
    )
};

export default ItemDay;