import React from 'react'
import { Container, Row, Col, Image, Nav } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import Logo from '../../images/trottr_web_logo.svg';


const PrivacyPolicy = () => {
    const { isAuthenticated } = useAuth0();
    return (
        <div className='main'>
            <Container>
                <Row>
                    <Col className='text-left mt-3'>
                        {!isAuthenticated && <Nav.Link as={Link} to="/"><Image className='w-25 mb-2'src={Logo} /></Nav.Link>}
                        <h1 className='text-dark heading-font'>Privacy Policy for Trottr</h1>
                        <p>At Trottr, safeguarding your privacy is our top priority. Our Privacy Policy outlines the information we collect and how it is utilized.</p>
                        <p>Should you have additional queries or require further details regarding our Privacy Policy, feel free to reach out to us via email at <a href="mailto: support@trottr.com">support@trottr.com</a>.</p>
                    </Col>
                </Row>
                <Row>
                    <Col className='text-left mt-4'>
                        <h3 className='text-dark heading-font'>1. General Data Protection Regulation (GDPR)</h3>
                        <p className='mt-3'>As a Data Controller under the European General Data Protection Regulation (GDPR), Trottr LLC ("Trottr," "we," or "us") operates based on specific legal grounds for collecting and using personal information, which may include the necessity to fulfill a contract, obtaining your consent, pursuing legitimate interests, or complying with the law. For a better understanding of your rights under the GDPR, you can refer to <a href="https://ico.org.uk/for-organisations/uk-gdpr-guidance-and-resources/individual-rights/individual-rights/">this resource provided by the UK Information Commissioner's Office.</a></p>
                        <p className='mt-3'>We will retain your personal information only for as long as necessary to fulfill the purposes outlined in this Privacy Policy. This includes compliance with legal obligations, resolution of disputes, and enforcement of our policies.</p>
                        <p className='mt-3'>If you are a resident of the European Economic Area (EEA), you possess certain data protection rights. If you wish to know what Personal Information we hold about you or request its removal from our systems, please contact us. You may also exercise rights such as access, rectification, objection, restriction, data portability, and withdrawal of consent under certain circumstances.</p>
                        <p className='mt-3'>If you are a resident of the European Economic Area (EEA), you have certain data protection rights. If you wish to be informed what Personal Information we hold about you and if you want it to be removed from our systems, please contact us.</p>
                    </Col>
                </Row>
                <Row>
                    <Col className='mt-4 text-left'>
                        <h3 className='heading-font text-dark'>2. Log Files</h3>
                        <p className='mt-3'>Trottr adheres to standard log file procedures, common among hosting companies and analytics services. These log files capture visitors' data, including internet protocol (IP) addresses, browser type, Internet Service Provider (ISP), date and time stamps, referring/exit pages, and potentially the number of clicks. This information, not linked to personally identifiable details, is utilized for trend analysis, site administration, user tracking, and demographic insights.</p>
                    </Col>
                </Row>
                <Row>
                    <Col className='mt-4 text-left'>
                        <h3 className='heading-font text-dark'>3. Cookies and Web Beacons</h3>
                        <p className='mt-3'>Similar to other websites, Trottr employs 'cookies' to store information like visitors' preferences and accessed/visited pages. This data helps optimize user experience by customizing web page content based on browser type and other preferences.</p>
                    </Col>
                </Row>
                <Row>
                    <Col className='mt-4 text-left'>
                        <h3 className='heading-font text-dark'>4. User-provided Information</h3>
                        <p className='mt-3'>To operate Trottr, we collect and process various information, including:</p>
                        <h4 className='mt-3 heading-font text-dark'>Google Data</h4>
                        <p className='mt-3'>If you choose to sign in with Google, we receive your email and Google account ID for authentication during sign-in and communication regarding product updates and account changes. Use of this data adheres to the Google API Services: User Data Policy.</p>
                        <p className='mt-3'>Use of this data adheres to the  <a href="https://developers.google.com/terms/api-services-user-data-policy">Google API Services: User Data Policy</a>.</p>
                        <h4 className='mt-3 heading-font text-dark'>User Profile Data</h4>
                        <p className='mt-3'>When signing up with an email and password, we retain this information for authentication and occasional communication regarding product updates and account changes. User profile details such as username, display name, and home base location, if provided, contribute to your public profile on Trottr.</p>
                        <h4 className='mt-3 heading-font text-dark'>Trip Plan Data</h4>
                        <p className='mt-3'>Information generated while using Trottr, such as selected places, comments, likes, and images/maps in trip logs, is stored and displayed when others view your authored trip logs.</p>
                        <h4 className='mt-3 heading-font text-dark'>Deleting Your Data</h4>
                        <p className='mt-3'>To delete your account and associated user data, sign in and visit our <a href="https://trottr.com/account">account page</a>, where a "Delete account" button initiates the complete deletion process within two weeks.</p>
                    </Col>
                </Row>
                <Row>
                    <Col className='mt-4 text-left'>
                        <h3 className='heading-font text-dark'>5. Children's Information</h3>
                        <p className='mt-3'>Ensuring children's online safety is a key focus. Trottr does not knowingly collect Personal Identifiable Information from children under the age of 13. If you believe your child has provided such information on our website, please contact us immediately, and we will promptly remove it from our records.</p>
                    </Col>
                </Row>
                <Row>
                    <Col className='mt-4 text-left'>
                        <h3 className='heading-font text-dark'>6. Updates</h3>
                        <p className='mt-3'>While we aim to maintain a consistent Privacy Policy, occasional updates may occur, sometimes with limited or no notice.</p>
                    </Col>
                </Row>
                <Row>
                    <Col className='mt-4 text-left mb-5'>
                        <h3 className='heading-font text-dark'>7. Consent</h3>
                        <p className='mt-3'>By utilizing our website, you hereby express your consent to our Privacy Policy and agree to its terms.</p>
                    </Col>
                </Row>
            </Container>
        </div>
    )
};

export default PrivacyPolicy;