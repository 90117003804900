import React from 'react';
import { useRecoilState } from 'recoil';
import { UserState } from '../Recoil/atoms';
import { Container, Row, Col, Image, Button, DropdownButton } from 'react-bootstrap';
import UploadButton from './UploadButton';
import { URL } from '../../config';
import axios from 'axios';
import BioDetails from './BioDetails';
import SocialLinks from './SocialLinks';
import { getUserInfo } from '../apiHelpers';
import { useNavigate } from 'react-router-dom';
import FollowCount from './FollowCount';
import DropdownItem from 'react-bootstrap/esm/DropdownItem';
import ReactGA from 'react-ga4';
import { Settings } from 'lucide-react';

const Profile = ({ user, setUser, followView }) => {
    const [followingUser, setFollowingUser] = useRecoilState(UserState);
    const navigate = useNavigate();

    const getProfilePic = () => {
        if (!!user.profile_image) {
            return `url(${URL}/${user.profile_image.filename})`;
        } else {
            return "none"
        }
    };

    const getProfileBackground = () => {
        if (!!user.background_image) {
            return `${URL}/${user.background_image.filename}`;
        } else {
            return "none";
        }
    }

    const uploadBackground = async (formData) => {
        ReactGA.event({
            category: 'Profile',
            action: 'User uploads profile background'
        })
        const res = await axios.post(`${URL}/users/${user.id}/profile/background`, formData, { headers: {'Content-Type': 'multipart/form-data'}});
        if (res.status === 201) {
            getUserInfo(user.email, setUser);
        }
    }

    const uploadProfile = async (formData) => {
        ReactGA.event({
            category: 'Profile',
            action: 'User uploads a profile picture'
        })
        const res = await axios.post(`${URL}/users/${user.id}/profile/image`, formData, { headers: {'Content-Type': 'multipart/form-data'}});
        if (res.status === 201) {
            getUserInfo(user.email, setUser);
        }
    }

    const requestFollow = async () => {
        ReactGA.event({
            category: 'Follow',
            action: 'User requests to follow another user'
        })
        const followed = {
            username: user.username,
            firstName: user.first_name,
            lastName: user.last_name,
            private: user.private,
            profilePic: user.profile_image
        }
        const follower = {
            username: followingUser.username,
            firstName: followingUser.first_name,
            lastName: followingUser.last_name,
            private: followingUser.private,
            profilePic: followingUser.profile_image
        }

        if (user.private === true) {
            const url = `${URL}/follow/request/${user.id}/follower/${followingUser.id}`;
            const res = await axios.post(url, { followed, follower });
            console.log('res from follow request: ', res);
            await getUserInfo(user.email, setUser);
            await getUserInfo(followingUser.email, setFollowingUser);
            return res;
        } else {
            const url = `${URL}/follow/accept/${user.id}/follower/${followingUser.id}`;
            const res = await axios.post(url, { follower, followed });
            console.log('Res from follow accept: ', res);
            await getUserInfo(user.email, setUser);
            await getUserInfo(followingUser.email, setFollowingUser);

            return res;
        }
    }

    const unfollowUser = async () => {
        ReactGA.event({
            category: 'Follow',
            action: 'User unfollows another user'
        })
        const url = `${URL}/follow/delete/follower/${followingUser.id}/user/${user.id}`;
        const res = await axios.delete(url);
        await getUserInfo(user.email, setUser);
        await getUserInfo(followingUser.email, setFollowingUser);
        return res;
    }

    const isFollowing = () => {
        // console.log('User being followed: ', user);
        if (!!user.followers && !!user.followers[followingUser.id] && !!followView) {
            return true;
        } else {
            return false;
        }
    };

    const followRequested = () => {
        if (!!user.pending_followers && user.pending_followers[followingUser.id]) {
            return true;
        } else {
            return false;
        }
    }

    const navigateToProfile = () => {
        ReactGA.event({
            category: 'Profile',
            action: 'User views own profile'
        })
        navigate(`/${user.username}`);
    } 

    const removeFollowRequest = async () => {
        ReactGA.event({
            category: 'Follow',
            action: 'User undoes follow request'
        })
        const url = `${URL}/follow/request/${user.id}/follower/${followingUser.id}`;
        const res = await axios.delete(url);
        console.log(res);
        await getUserInfo(user.email, setUser);
        // await getUserInfo(followingUser.email, setFollowingUser);
        return res;
    }

    const followButtonViewable = () => {
        if (!!followView && user.id !== followingUser.id) {
            return true;
        } else {
            return false;
        }
    }

    return (
        <Container className='pr-0 pl-0'>
            <Row className='p-0 m-0'>
                <Col className='profile-background-img-container position-relative'>
                    <Image className='w-100 full-center' src={getProfileBackground()} />
                    {!followView && (
                        <div className='position-absolute p-1 mr-1 mt-1 cursor-pointer' 
                                style={{ top: "0", right: "0", borderRadius: "50%", backgroundColor: "#424242" }}
                                onClick={() => navigate('/settings')}
                            >
                                <Settings color="lightgray" size={25} />
                        </div>
                    )}
                    {!followView && (
                        <div className='upload-camera-container'>
                            <UploadButton uploadRequest={uploadBackground} />
                        </div>
                    )}
                </Col>
            </Row>
            <Row className='pl-3 pr-0 pt-0 pb-0 ml-0 mr-0 mb-2'>
                <Col className='text-center col-md-3 col-4 pr-2'>
                    <div className='profile-pic-img-container position-relative w-100'>
                        {!!user.profile_image && (
                            <Image className='w-100 full-center' src={`${URL}/${user.profile_image.filename}`} />
                        )}
                        {!followView && (
                        <div className='upload-camera-container'>
                            <UploadButton uploadRequest={uploadProfile} />
                        </div>
                        )}
                    </div>
                    <div className='text-center'>
                        <h5 className="mt-1 pt-0 mt-0 mb-2">@{user.username}</h5>
                    </div>
                    {isFollowing() && !followRequested() && followButtonViewable() && (
                        // <Button size='md' className='btn-light mb-2' onClick={unfollowUser} >Unfollow</Button>
                        <DropdownButton className='btn-following mb-2' title='Following'>
                            <DropdownItem onClick={unfollowUser} >Unfollow</DropdownItem>
                        </DropdownButton>
                    )}
                    {!isFollowing() && !followRequested() && followButtonViewable() && (
                        <Button size='md' className='btn-secondary mb-2' onClick={requestFollow} >Follow</Button>
                    )}
                    {followRequested() && followButtonViewable() && (
                        <Button size='md' className='btn-light mb-2' onClick={removeFollowRequest} >Requested</Button>
                    )}
                    {!followView && (
                        <Button size='sm' className='btn-primary rounded-pill mb-2' onClick={navigateToProfile} >View Profile</Button>
                    )}
                    <div className='m-0 p-0 desktop-hide'>
                        <FollowCount user={user} setUser={setUser} />
                    </div>
                </Col>
                <Col className='pl-1 pr-0 pt-0 pb-0 text-left col-md-9 col-8'>
                    <h3 className="pt-1 pb-0 mt-2 mb-0 pl-0 text-dark">{user.first_name + ' ' + user.last_name}</h3>
                    <BioDetails user={user} setUser={setUser} followView={followView} />
                    <Row className='m-0 p-0'>
                        <Col className='m-0 p-0 col-md-10 col-12'>
                            <SocialLinks user={user} setUser={setUser} followView={followView} />
                        </Col>
                        <Col className='m-0 p-0 col-md-2 col-4 mobile-hide'>
                            <FollowCount user={user} setUser={setUser} />
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>
    )

};

export default Profile;