import React from 'react';
import { Auth0Provider } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';
import { AUTH0_DOMAIN, AUTH0_CLIENTID, CALLBACK_URL} from '../../config';

const Auth0ProviderWithNavigate = ({ children, ...props }) => {

    const navigate = useNavigate();
    const onRedirectCallback = (appState) => {
        navigate(appState?.returnTo || window.location.pathname);
    }


    
    return (
        <Auth0Provider
            domain={AUTH0_DOMAIN}
            clientId={AUTH0_CLIENTID}
            authorizationParams={{
                redirect_uri: window.location.origin,
            }}
            onRedirectCallback={onRedirectCallback}
        >
            {children}
        </Auth0Provider>

    )
};

export default Auth0ProviderWithNavigate;