import React, { useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Button, Modal, Alert } from 'react-bootstrap';
import axios from 'axios';
import { URL } from '../../config';
import ReactGA from 'react-ga4';


const DeleteAccountButton = () => {
    const { logout, user } = useAuth0();
    const [show, setShow] = useState(false);
    const [showAlert, setShowAlert] = useState(false);

    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);

    const deleteAccount = async () => {
        const email =  user.email;
        const userid = user.sub;
        return axios.delete(`${URL}/users/account`, { params: { email, userid }})
        .then(() => {      
            setShow(false)
            setShowAlert(true);
            setTimeout(() => {
                logout({ logoutParams: { returnTo: window.location.origin } });
                document.cookie = 'user' + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
                document.cookie = 'email=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
            }, 1000)
            ReactGA.event({
                category: "User Accounts",
                action: "User Deletes Account"
            })
        })
        // .then(res => console.log('deleted user: ', res))
        .catch(err => {
            console.log('Error deleting user: ', err);
        })
    }

    return (
        <>
            {showAlert && (
                <div style={{ position: "absolute", top: "0" }}>
                    <Alert variant="success" onClose={() => setShowAlert(false)}>
                        <Alert.Heading>Account Successfully Deleted!</Alert.Heading>
                    </Alert>
                </div>
            )}
            <h6
                className='text-danger'
                onClick={handleShow}
                style={{ cursor: "pointer" }}
            >
                Delete Account
            </h6>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Delete Your Account</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Are you sure you would like to delete your account?<br></br>
                    
                    You will lose all of your data including your saved places.
                </Modal.Body>
                <Modal.Footer>
                    <Button variant='danger' onClick={deleteAccount}>
                        DELETE
                    </Button>
                    <Button variant='secondary' onClick={handleClose}>Cancel</Button>
                </Modal.Footer>
            </Modal>
        </>
    )
};

export default DeleteAccountButton;