import React, { useEffect, useState } from 'react';
import { Row, Col, Button, Alert, Modal } from 'react-bootstrap';
import { getFolders } from '../apiHelpers';
import { folderState } from '../Recoil/atoms';
import { useRecoilState } from 'recoil';
import ReactGA from 'react-ga4';
import Place from './Place';

const PublishButton = ({ blogView, item, folder, user, publishBlogPost, index, updateBlogPost, postView, folderId }) => {
    const [showPublishAlert, setShowPublishAlert] = useState(false);
    const [folders, setFolders] = useRecoilState(folderState);
    const [showModal, setShowModal] = useState(false);
    const [showEditAlert, setShowEditAlert] = useState(false);

    const toggleModal = () => setShowModal(showModal => !showModal);

    const handlePublish = async () => {
        ReactGA.event({
            category: "Feed",
            action: "User Posts to Feed"
        })
        const res = await publishBlogPost(user.id, user.username, folder.id, folder.name, item, user.profile_image, index);
        if (res.status === 201) {
            setShowPublishAlert(true);
            setTimeout(() => setShowPublishAlert(false), 2000);
            getFolders(user, setFolders);
        }
    }

    const handleEdit = async () => {
        const res = await updateBlogPost(user.id, folder.id, item.place_id, item);
        if (res.status === 204) {
            setShowEditAlert(true);
            setTimeout(() => setShowEditAlert(false), 2000);
        }
    }

    return (
        <>  {!!blogView && (
                <Button className='btn-primary mt-3' size='sm' onClick={toggleModal}>Preview Post</Button>
            )}
            <Modal dialogClassName='wide-modal' show={showModal} onHide={toggleModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Create New Post</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Place item={item} followView={true} postView={true} blogView={false} />
                    {!!showPublishAlert && !postView && (
                        <Row className='mt-3'>
                            <Col>
                                <Alert variant='success' classID='p-1 text-center'>Successfully Published to Feed!</Alert>
                            </Col>
                        </Row>
                        )}
                    {!!blogView && !item.published && (
                        <Row className='mt-3'>
                            <Col className='text-center'>
                                <Button size="md" onClick={handlePublish}>Publish to Feed</Button>
                            </Col>
                        </Row>
                    )}
                    {!!showEditAlert && !postView && (
                        <Row className='mt-3'>
                            <Col>
                                <Alert variant='success' classID='p-1 text-center'>Successfully Published Edits!</Alert>
                            </Col>
                        </Row>
                    )}
                    {!!blogView && !!item.published && (
                        <Row className='mt-3'>
                            <Col className='text-center'>
                                <Button variant='secondary' size="md" onClick={handleEdit}>Publish Edits</Button>
                            </Col>
                        </Row>
                    )}
                </Modal.Body>
                <Modal.Footer className='bg-light'>
                </Modal.Footer>
            </Modal>
        </>
    )
};

export default PublishButton;