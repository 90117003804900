import React from 'react'
import { Container, Row, Col, Image, Nav } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import Logo from '../../images/trottr_web_logo.svg';


const TermsOfService = () => {
    const { isAuthenticated } = useAuth0();
    return (
        <div className='main'>
            <Container>
                <Row>
                    <Col className='text-left mt-3'>
                        {!isAuthenticated && <Nav.Link as={Link} to="/"><Image className='w-25 mb-2'src={Logo} /></Nav.Link>}
                        <h1 className='text-dark heading-font'>Terms of Service for Trottr</h1>
                    </Col>
                </Row>
                <Row>
                    <Col className='text-left mt-4'>
                        <h3 className='text-dark heading-font'>1. Introduction</h3>
                        <p className='mt-3'>Welcome to Trottr ("Trottr LLC," "Company," "we," "our," "us")! Here are our Terms of Service. It should take you approximately 10 minutes to go through them.</p>
                        <p className='mt-3'>These Terms of Service ("Terms," "Terms of Service") govern your use of our web pages located at https://trottr.com (referred to as "Service") operated by Trottr LLC. Your use of our Service is also subject to our Privacy Policy, which explains how we collect, safeguard, and disclose information resulting from your use of our web pages. Please read our Privacy Policy at https://trottr.com/privacy.</p>
                        <p className='mt-3'>Your agreement with us includes both these Terms and our Privacy Policy ("Agreements"). By using the Service, you acknowledge that you have read and understood the Agreements, and you agree to be bound by them. If you disagree with or cannot comply with the Agreements, please refrain from using the Service, but do let us know by emailing support@trottr.com, and we'll try to find a solution. These Terms apply to all visitors, users, and others seeking to access or use the Service.</p>
                        <p className='mt-3'>Thank you for being resposible.</p>
                    </Col>
                </Row>
                <Row>
                    <Col className='text-left mt-4'>
                        <h3 className='text-dark heading-font'>2. Communications</h3>
                        <p className='mt-3'>Upon creating an Account on our Service, you agree to receive newsletters, marketing or promotional materials, and other information we may send. However, you can opt out of receiving any or all of these communications by following the unsubscribe link or by emailing us at support@trottr.com.</p>
                    </Col>
                </Row>
                <Row>
                    <Col className='text-left mt-4'>
                        <h3 className='text-dark heading-font'>3. Content</h3>
                        <p className='mt-3'>Our Service allows you to post, link, store, share, and otherwise make available certain information, text, graphics, videos, or other material ("Content"). You are responsible for the Content you post on or through the Service, including its legality, reliability, and appropriateness.</p>
                        <p className='mt-3'>By posting Content on or through the Service, you represent and warrant that the Content is yours (you own it) and/or you have the right to use it, granting us the rights and license as provided in these Terms. Additionally, you affirm that the posting of your Content on or through the Service does not violate the rights of any person or entity. We reserve the right to terminate the account of anyone found to be infringing on a copyright.</p>
                        <p className='mt-3'>While you retain your rights to any Content you submit, post, or display on or through the Service, you grant us the right and license to use, modify, publicly perform, publicly display, reproduce, and distribute such Content on and through the Service. This license also allows us to make your Content available to other users of the Service, who may use your Content subject to these Terms.</p>
                        <p className='mt-3'>Trottr LLC has the right (but not the obligation) to monitor and edit all Content provided by users.</p>
                        <p className='mt-3'>In addition, Content found on or through this Service belongs to Trottr LLC or is used with permission. You may not distribute, modify, transmit, reuse, download, repost, copy, or use said Content, whether in whole or in part, for commercial purposes or personal gain, without express advance written permission from us.</p>
                        <h4 className='text-dark heading-font mt-3'>Objectionable Content</h4>
                        <p className='mt-3'>You are not allowed to post content that is offensive, insensitive, upsetting, intended to disgust, or in exceptionally poor taste. Examples of such content include defamatory, discriminatory, or mean-spirited content, depictions encouraging violence or illegal use of weapons, overtly sexual or pornographic material, inflammatory religious commentary, and intentionally false and misleading information. If you discover objectionable content, please alert us by emailing support@trottr.com. We will usually take action within 24 hours, promptly removing objectionable content and expelling users who post it.</p>
                    </Col>
                </Row>
                <Row>
                    <Col className='text-left mt-4'>
                        <h3 className='text-dark heading-font'>4. Prohibited Uses</h3>
                        <p className='mt-3'>You may use the Service only for lawful purposes and in accordance with these Terms. Prohibited uses include violating any applicable national or international law, exploiting or harming minors, transmitting spam or unauthorized solicitations, impersonating others, engaging in illegal, threatening, or fraudulent activities, and interfering with the proper working of the Service. Additional restrictions on usage are outlined in this section.</p>
                    </Col>
                </Row>
                <Row>
                    <Col className='text-left mt-4'>
                        <h3 className='text-dark heading-font'>5. No Use By Minors</h3>
                        <p className='mt-3'>The Service is intended only for individuals aged eighteen (18) or older. By accessing or using the Service, you warrant and represent that you are at least eighteen (18) years old and have the authority, right, and capacity to enter into this agreement.</p>
                    </Col>
                </Row>
                <Row>
                    <Col className='text-left mt-4'>
                        <h3 className='text-dark heading-font'>6. Accounts</h3>
                        <p className='mt-3'>When creating an account with us, you guarantee that you are above the age of 18, and the information provided is accurate, complete, and current. You are responsible for maintaining the confidentiality of your account and notifying us of any unauthorized use.</p>
                    </Col>
                </Row>
                <Row>
                    <Col className='text-left mt-4'>
                        <h3 className='text-dark heading-font'>7. Error Reporting and Feedback</h3>
                        <p className='mt-3'>You can directly provide us with information and feedback related to errors, suggestions for improvements, ideas, problems, complaints, and other matters concerning our Service at support@trottr.com ("Feedback"). By doing so, you acknowledge and agree to the following: (i) you won't assert any intellectual property right or other interest in the Feedback; (ii) Company may have similar development ideas independently; (iii) Feedback doesn't contain confidential or proprietary information from you or any third party; and (iv) Company has no obligation of confidentiality regarding the Feedback. If transferring ownership of the Feedback is not possible due to mandatory laws, you grant Company and its affiliates an exclusive, transferable, irrevocable, free-of-charge, sub-licensable, unlimited, and perpetual right to use (including copy, modify, create derivative works, publish, distribute, and commercialize) the Feedback in any manner and for any purpose.</p>
                    </Col>
                </Row>
                <Row>
                    <Col className='text-left mt-4'>
                        <h3 className='text-dark heading-font'>8. Links to Other Websites</h3>
                        <p className='mt-3'>Our Service may include links to web sites or services operated by third parties that are not owned or controlled by Trottr LLC.</p>
                        <p className='mt-3'>Trottr LLC does not have control over and assumes no responsibility for the content, privacy policies, or practices of any third-party websites or services. We do not guarantee the offerings of these entities or individuals, including the content on their websites.</p>
                        <p className='mt-3'>BY USING OUR SERVICE, YOU ACKNOWLEDGE AND AGREE THAT TROTTR LLC IS NOT RESPONSIBLE OR LIABLE, DIRECTLY OR INDIRECTLY, FOR ANY DAMAGE OR LOSS CAUSED OR ALLEGED TO BE CAUSED BY OR IN CONNECTION WITH THE USE OF OR RELIANCE ON ANY SUCH CONTENT, GOODS, OR SERVICES AVAILABLE ON OR THROUGH ANY THIRD-PARTY WEBSITES OR SERVICES.</p>
                        <p className='mt-3'>WE STRONGLY RECOMMEND THAT YOU REVIEW THE TERMS OF SERVICE AND PRIVACY POLICIES OF ANY THIRD-PARTY WEBSITES OR SERVICES YOU VISIT.</p>
                    </Col>
                </Row>
                <Row>
                    <Col className='text-left mt-4'>
                        <h3 className='text-dark heading-font'>9. Disclaimer of Warranty</h3>
                        <p className='mt-3'>THE SERVICES PROVIDED BY THE COMPANY ARE OFFERED ON AN "AS IS" AND "AS AVAILABLE" BASIS. THE COMPANY DOES NOT MAKE ANY REPRESENTATIONS OR WARRANTIES, WHETHER EXPRESS OR IMPLIED, REGARDING THE OPERATION OF THESE SERVICES OR THE INFORMATION, CONTENT, OR MATERIALS INCLUDED THEREIN. YOU EXPLICITLY AGREE THAT YOUR USE OF THESE SERVICES, THEIR CONTENT, AND ANY SERVICES OR ITEMS OBTAINED FROM THE COMPANY IS SOLELY AT YOUR OWN RISK.</p>
                        <p className='mt-3'>NEITHER THE COMPANY NOR ANY INDIVIDUAL ASSOCIATED WITH THE COMPANY PROVIDES ANY WARRANTY OR REPRESENTATION CONCERNING THE COMPLETENESS, SECURITY, RELIABILITY, QUALITY, ACCURACY, OR AVAILABILITY OF THE SERVICES. TO ELABORATE, NEITHER THE COMPANY NOR ANYONE ASSOCIATED WITH THE COMPANY ASSURES OR WARRANTS THAT THE SERVICES, THEIR CONTENT, OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE SERVICES WILL BE ACCURATE, RELIABLE, ERROR-FREE, OR UNINTERRUPTED. THERE IS NO GUARANTEE THAT DEFECTS WILL BE CORRECTED, THAT THE SERVICES OR THE SERVER MAKING THEM AVAILABLE ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS, OR THAT THE SERVICES OR ANY SERVICES OR ITEMS OBTAINED THROUGH THEM WILL MEET YOUR NEEDS OR EXPECTATIONS.</p>
                        <p className='mt-3'>THE COMPANY EXPLICITLY DISCLAIMS ALL WARRANTIES, WHETHER EXPRESS OR IMPLIED, STATUTORY, OR OTHERWISE, INCLUDING BUT NOT LIMITED TO WARRANTIES OF MERCHANTABILITY, NON-INFRINGEMENT, AND FITNESS FOR A PARTICULAR PURPOSE.</p>
                        <p className='mt-3'>THESE DISCLAIMERS DO NOT AFFECT ANY WARRANTIES THAT CANNOT BE EXCLUDED OR LIMITED UNDER APPLICABLE LAW.</p>
                    </Col>
                </Row>
                <Row>
                    <Col className='text-left mt-4'>
                        <h3 className='text-dark heading-font'>10. Limitation of Liability</h3>
                        <p className='mt-3'>UNLESS PROHIBITED BY LAW, YOU AGREE TO INDEMNIFY AND HOLD US, ALONG WITH OUR OFFICERS, DIRECTORS, EMPLOYEES, AND AGENTS, HARMLESS FROM ANY INDIRECT, PUNITIVE, SPECIAL, INCIDENTAL, OR CONSEQUENTIAL DAMAGES, REGARDLESS OF HOW THEY ARISE (INCLUDING ATTORNEYS' FEES AND ALL ASSOCIATED COSTS AND EXPENSES RELATED TO LITIGATION AND ARBITRATION, WHETHER INITIATED DURING LEGAL PROCEEDINGS, ARBITRATION, OR AT TRIAL OR ON APPEAL). THIS INCLUDES DAMAGES ARISING FROM A CONTRACTUAL ACTION, NEGLIGENCE, OR ANY OTHER TORTIOUS ACTION, IN CONNECTION WITH OR ARISING OUT OF THIS AGREEMENT. THIS INDEMNIFICATION ENCOMPASSES CLAIMS FOR PERSONAL INJURY OR PROPERTY DAMAGE RESULTING FROM THIS AGREEMENT AND ANY VIOLATION BY YOU OF FEDERAL, STATE, OR LOCAL LAWS, STATUTES, RULES, OR REGULATIONS, EVEN IF THE COMPANY WAS PREVIOUSLY ADVISED OF THE POSSIBILITY OF SUCH DAMAGE.</p>
                        <p className='mt-3'>UNLESS PROHIBITED BY LAW, IF THE COMPANY IS FOUND LIABLE, SUCH LIABILITY WILL BE LIMITED TO THE AMOUNT PAID FOR THE PRODUCTS AND/OR SERVICES, AND UNDER NO CIRCUMSTANCES WILL THERE BE CONSEQUENTIAL OR PUNITIVE DAMAGES. IT'S IMPORTANT TO NOTE THAT SOME STATES MAY NOT PERMIT THE EXCLUSION OR LIMITATION OF PUNITIVE, INCIDENTAL, OR CONSEQUENTIAL DAMAGES, SO THE AFOREMENTIONED LIMITATION OR EXCLUSION MAY NOT BE APPLICABLE TO YOU.</p>
                    </Col>
                </Row>
                <Row>
                    <Col className='text-left mt-4'>
                        <h3 className='text-dark heading-font'>10. Termination</h3>
                        <p className='mt-3'>We reserve the right to terminate or suspend your account and deny access to the Service at our sole discretion, with immediate effect and without prior notice or liability, for any reason whatsoever, including, but not limited to, a violation of the Terms.</p>
                        <p className='mt-3'>Should you decide to terminate your account, you can do so by discontinuing the use of the Service.</p>
                        <p className='mt-3'>All provisions of the Terms that, by their nature, should continue to be in effect after termination will survive, including, but not limited to, ownership provisions, warranty disclaimers, indemnity, and limitations of liability.</p>
                    </Col>
                </Row>
                <Row>
                    <Col className='text-left mt-4'>
                        <h3 className='text-dark heading-font'>11. Governing Law</h3>
                        <p className='mt-3'>These Terms are governed by the laws of the State of New York, without regard to its conflict of law provisions.</p>
                        <p className='mt-3'>The fact that we do not enforce any right or provision outlined in these Terms does not imply a waiver of those rights. In the event that a court deems any provision of these Terms to be invalid or unenforceable, the remaining provisions will still be valid and enforceable. These Terms represent the complete agreement between us concerning our Service and override any previous agreements we may have had regarding the Service.</p>
                    </Col>
                </Row>
                <Row>
                    <Col className='text-left mt-4'>
                        <h3 className='text-dark heading-font'>12. Changes To Service</h3>
                        <p className='mt-3'>Trottr LLC reserves the right to withdraw or amend the Service at its discretion without notice. Users are advised to review these Terms periodically for any changes.</p>
                    </Col>
                </Row>
                <Row>
                    <Col className='text-left mt-4'>
                        <h3 className='text-dark heading-font'>13. Amendments To Terms</h3>
                        <p className='mt-3'>Terms may be amended at any time by posting the amended terms on the site. Continued use of the Service after revisions indicates acceptance of the changes. If you do not agree to the new terms, you are no longer authorized to use Service.</p>
                    </Col>
                </Row>
                <Row>
                    <Col className='text-left mt-4'>
                        <h3 className='text-dark heading-font'>14. Waiver And Severability</h3>
                        <p className='mt-3'>No waiver by Company of any term or condition in Terms shall be deemed a continuing waiver. If any provision is found invalid, the remaining provisions will continue in full force and effect.</p>
                    </Col>
                </Row>
                <Row>
                    <Col className='text-left mt-4'>
                        <h3 className='text-dark heading-font'>15. Acknowledgement</h3>
                        <p className='mt-3'>BY USING THE SERVICE, USERS ACKNOWLEDGE THEY HAVE READ AND AGREED TO THESE TERMS OF SERVICE.</p>
                    </Col>
                </Row>
                <Row>
                    <Col className='text-left mt-4 mb-5'>
                        <h3 className='text-dark heading-font'>16. Contact Us</h3>
                        <p className='mt-3'>For support, technical issues, feedback and comments contact <a href="mailto: support@trottr.com">support@trottr.com</a></p>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default TermsOfService;