import { atom } from 'recoil';

export const folderState = atom({
    key: 'folderState',
    default: [],
});

export const loggedInState = atom({
    key: 'loggedInState',
    default: false,
});
``
export const authState = atom({
    key: 'AuthState',
    default: false,
});

export const usernameState = atom({
    key: 'username',
    default: '',
});

export const UserState = atom({
    key: 'User',
    default: false,
});

export const addAbleState = atom({
    key: 'addAble',
    default: true,
});

export const newsFeedState = atom({
    key: 'news_feed',
    default: undefined,
});

export const followedUserState = atom({
    key: 'followed_user',
    default: false,
})




