import React, { useState, useEffect, useRef } from 'react';
import { Container, Row, Col, Button, Form, Alert, Badge } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import ReactGA from 'react-ga4';
import { UserState, folderState } from '../Recoil/atoms';
import { useRecoilState } from 'recoil';
import { URL as URL2 } from '../../config';
import { getFolders } from '../apiHelpers';
import axios from 'axios';
import SearchModal from '../DiscoverView/SearchModal';
import { ImagePlus } from 'lucide-react';


const NewBlog = () => {
    const [tripName, setTripName] = useState('');
    const [file, setFile] = useState(null);
    const [error, setError] = useState(false);
    const [user, setUser] = useRecoilState(UserState);
    const [folders, setFolders] = useRecoilState(folderState);
    const [success, setSuccess] = useState(false);
    const [places, setPlaces] = useState([]);
    const inputRef = useRef(null);
    const navigate = useNavigate();

    const createMemory = async (e) => {
        e.preventDefault();
        ReactGA.event({
            category: "Blogs",
            action: "User Creates New Blog"
        })
        if (tripName.length < 1 || file === null) {
            setError(true)
        } else {
            setError(false);
        }

        const formData = new FormData();
        const imageFile = inputRef.current.files[0];
        formData.append('image', imageFile);
        formData.append('name', tripName);
        formData.append('locations', JSON.stringify(places));
        const res = await axios.post(`${URL2}/trips/create/memory/name/${tripName}/user/${user.id}/`, formData);
        if (res.status === 201) {
            setSuccess(true);
            await getFolders(user, setFolders);
            setTimeout(() => navigate('/profile/share'), 2000);
            setTimeout(() => setSuccess(false), 1800);
        }
    }

    const handleUpload = () => {
        inputRef.current?.click();
    }

    const setImage = (e) => {
        setFile(URL.createObjectURL(inputRef.current.files[0]))
    }


    return (
        <div className='main'>
            <Container>
                <Row>
                    <Col className='col-md-6 offset-md-3 mt-3'>
                        <h3>New Album</h3>
                        <Form>
                            <input
                                ref={inputRef}
                                onChange={setImage}
                                className='d-none'
                                type='file'
                                name='file'
                                accept='image/*'
                            />
                            <Row className='text-left mt-3 border-bottom border-black-50 shadow'>
                                <Col>
                                    <Row>
                                        <Col className='trip-cover-img position-relative' style={{ backgroundImage: file ? `url(${file})` : "none" }}>
                                            {!file && (
                                                <Button className='btn-transparent full-center' onClick={handleUpload}>
                                                    <ImagePlus size={64} color='white' />
                                                    <p className='text-white'>Add Background Photo</p>
                                                </Button>
                                            )}
                                        </Col>
                                        {/* <Image className='mw-100' src={file} alt='trip background' /> */}
                                    </Row>
                                    <Row>
                                        <Col className='mt-2 mb-2'>
                                            <Form.Control placeholder='Name a Past Trip!' value={tripName} onChange={(e) => setTripName(e.target.value)} />
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row className='mt-3'>
                                <Col>
                                    <SearchModal setPlaces={setPlaces} postView={true} blogView={false} places={places} iconSize={64} />
                                    <div className='mt-3'>
                                    {places.length > 0 && places.map((place, i) => (
                                        <h4 className='d-inline mr-2' key={i}>
                                            <Badge className='p-2' variant='light'>{place.formatted_address}</Badge>
                                        </h4>
                                    ))}
                                    </div>
                                </Col>
                            </Row>
                            <Row className='mt-3 text-center'>
                                <Col>
                                    <Button className='rounded-pill' onClick={createMemory}>Create</Button>
                                </Col>
                            </Row>
                            {!!success && (
                                <Row className='mt-3 text-center'>
                                    <Col>
                                        <Alert variant='success'>Album Created!</Alert>
                                    </Col>
                                </Row>
                            )}
                        </Form>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default NewBlog;