import React from 'react';
import { useRecoilState } from 'recoil';
import { UserState } from '../Recoil/atoms';
import axios from 'axios';
import { Row, Col, Button } from 'react-bootstrap';
import { URL } from '../../config';
import { getUserInfo } from '../apiHelpers';
import ReactGA from 'react-ga4';


const AccountVisible = () => {
    const [user, setUser] = useRecoilState(UserState);

    const submitProfilePrivate = async (e) => {
        ReactGA.event({
            category: "Follow",
            action: "User makes profile private"
        })
        const url = `${URL}/users/${user.id}/account/visibility/private`;
        const res = await axios.post(url);
        console.log('Res from making profile private: ', res);
        if (res.status === 200) {
            await getUserInfo(user.email, setUser);
        }
        return res;
    }

    const submitProfilePublic = async (e) => {
        ReactGA.event({
            category: "Follow",
            action: "User makes profile public"
        })
        const url = `${URL}/users/${user.id}/account/visibility/public`;
        const res = await axios.post(url);
        console.log('Res from making user profile public: ', res);
        if (res.status === 200) {
            await getUserInfo(user.email, setUser);
        }
        return res;
    }


    return (
        <Row>
            <Col className='text-left d-inline'>
                <p className='mb-1 d-inline'>
                    <span className='font-weight-bold'>Account Visibility: </span>{!!user.private ? 'Private' : 'Public'}
                </p>
                {!!user.private ? (
                    <Button size='sm' className='btn-primary d-inline ml-3' onClick={submitProfilePublic} >Make Public</Button>
                ) : (
                    <Button size='sm' className='btn-secondary d-inline ml-3' onClick={submitProfilePrivate} >Make Private</Button>
                )}
            </Col>
        </Row>
    )
}

export default AccountVisible;