import React, { useState } from 'react';
import { useRecoilState } from 'recoil';
import { folderState, UserState } from '../Recoil/atoms.js';
import { Container, Row, Col } from 'react-bootstrap';
import SignUpForm from '../HomePage/SignUpForm.js';
import BlogCard from './BlogCard.js';
import { Plus } from 'lucide-react';
import { useNavigate } from 'react-router-dom';


const MyBlogs = () => {
    const [currentFolder, setCurrentFolder] = useState({});
    const [folders, setFolders] = useRecoilState(folderState);
    const [userInfo, setUserInfo] = useRecoilState(UserState);

    const navigate = useNavigate();

  
    return (
        <>
        {userInfo === undefined && (
            <SignUpForm />
        )}
        {!!userInfo
            && (
            <Container>
            <Row>
                <Col>
                    <Row>
                        <Col>
                            <div className='cursor-pointer' onClick={() => navigate('/post')}>
                                <Plus color='#424242' size={64} />
                                <p className='text-body'>New Album</p>
                            </div>
                        </Col>
                    </Row>

                    <Row className="mb-3">
                        <Col className='pl-0 pr-0'>
                            {!!folders && folders.map((folder, i) => {
                                if (folder.blog_view) {
                                    return (
                                        <div key={i}>
                                            <BlogCard folder={folder} setCurrentFolder={setCurrentFolder} currentFolder={currentFolder} blogView={true} />
                                        </div>
                                    )
                                }
                            })}
                        </Col>
                    </Row>
                </Col>
        </Row>
        </Container>
        )}
        </>
    );
};

export default MyBlogs;