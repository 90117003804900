import React, { useState, useEffect } from 'react'
import { Row, Col, Button, Form } from 'react-bootstrap';
import ReactGA from 'react-ga4';
import { folderState } from '../Recoil/atoms';
import { Pencil } from 'lucide-react';

const Notes = ({ myView, blogView, followView, folder, item, updateItemNotes, index, postView, folderId }) => {
    const [showNoteInput, setShowNoteInput] = useState(false);
    const [notes, setNotes] = useState(!!item.notes ? item.notes : '');
    const [folders, setFolders] = useState(folderState);

    const handleSubmitNotes = (e) => {
        e.preventDefault();
        ReactGA.event({
            category: "Trips",
            action: "User Added Notes to Itinerary Item"
        })
        updateItemNotes(index, notes, folderId);
        setShowNoteInput(false);
    };

    useEffect(() => {
        if (notes != item.notes) {
            setNotes(() => !!item.notes ? item.notes : '');
        }
    }, [item]);

    return (
        <>
            {(!!myView || !!blogView) && !!showNoteInput && (
                <Row className='mb-0'>
                    <Col className='mt-0 mb-0'>
                        <Form onSubmit={handleSubmitNotes}>
                            <Form.Group as={Row} className='text-left mt-0 mb-1'>
                                <Col className='mt-3'>
                                    <h6 className='m-0'>{!!myView ? 'Notes:' : 'Caption:'}</h6>
                                </Col>
                            </Form.Group>
                            <Form.Group className='mb-1'>
                                <Form.Control as="textarea" rows={2} placeholder={!!myView ? 'Add notes to this item in your itinerary.' : 'Add a caption to this place.'} value={notes} onChange={e => setNotes(e.target.value)} id='notesInput' />
                            </Form.Group>
                            <Form.Group as={Row} className='mt-1 mb-1'>
                                <Col className='right-align d-inline'>
                                    {/* <Button className='btn-primary p-1 d-inline' type="submit" size="sm"><Image className='m-0 filter-white' style={{ height: "1.8em" }} src={SaveIcon} /></Button> */}
                                    <Button className='btn-secondary pr-2 pl-2 pt-1 pb-1 d-inline ml-1' onClick={() => setShowNoteInput(false)} >Cancel</Button>
                                    <Button className='btn-primary pr-2 pl-2 pt-1 pb-1 d-inline ml-2' type="submit" size="md">Save</Button>
                                </Col>
                            </Form.Group>
                        </Form>
                    </Col>
                </Row>
            )}
            {(!!myView || !!blogView) && !showNoteInput && (
                <Row className='mb-0'>
                    <Col className='mt-0 mb-0'>
                        <Row className='mt-0 mb-0'>
                            <Col className='mt-3 col-md-6 left-align'>
                                <h6 className='m-0'>{!!myView ? 'Notes: ' : 'Caption: '}</h6>
                            </Col>
                            <Col className='mt-1 cold-md-6 right-align'>
                                <Button size="sm" className='btn-transparent' onClick={() => setShowNoteInput(true)}><Pencil color='#424242' size={20} /></Button>
                            </Col>
                        </Row>
                        <Row className='text-left'>
                            <Col>
                                <p className='text-dark mb-1'>{!!item.notes ? item.notes : !!myView ? 'Add notes to this item in your itinerary.' : 'Add a caption to this place.'}</p>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            )}
            {!!followView && !!item.notes && (
                <Row className='mb-0'>
                    <Col className='mt-0 mb-0'>
                        {/* <Row className='mt-0 mb-0'>
                            <Col className='mt-3 col-md-6 left-align'>
                                <h6 className='m-0'>Description:</h6>
                            </Col>
                        </Row> */}
                        <Row className='text-left'>
                            <Col>
                                <p className='text-dark mb-1'>{item.notes}</p>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            )}
        </>
    )
};

export default Notes;