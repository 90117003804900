import React, { useState } from 'react';
import { Row, Col, Button, Image } from 'react-bootstrap';
import axios from 'axios';
import { UserState } from '../Recoil/atoms';
import { useRecoilState } from 'recoil';
import { URL } from '../../config';
import { useNavigate } from 'react-router-dom';
import ReactGA from 'react-ga4';


const FollowRequest = ({ follower, setNotifications }) => {
    const [user, setUser] = useRecoilState(UserState);
    const [accepted, setAccepted] = useState(false);
    const [declined, setDeclined] = useState(false);
    const [confirmed, setConfirmed] = useState(false);
    const [requested, setRequested] = useState(false);
    const navigate = useNavigate();
    const [newFollower, setNewFollower] = useState({});
    // useEffect(() => {
    //     console.log('Is follower still in state: ', newFollower);
    //     if (!!follower) {
    //         setNewFollower({ id: follower[0], username: follower[1].username, firstName: follower[1].firstName, lastName: follower[1].lastName, private: follower[1].private, profilePic: follower[1].profilePic})
    //     }
    // },[follower])
    console.log('new follower: ', follower[1]);
    const declineFollow = async () => {
        console.log('Declining follow request from ', follower);
        ReactGA.event({
            category: 'Follow',
            action: 'User declines follow request'
        })
        setDeclined(true);
        '/follow/request/:followedid/follower/:followerid'
        const url = `${URL}/follow/request/${user.id}/follower/${follower[0]}`;
        const res = await axios.delete(url);
        console.log(res);
        setNotifications(notifications => notifications -1)
        return res;
    }

    const acceptFollow = async (follower) => {
        ReactGA.event({
            category: 'Follow',
            action: 'User accepts follow request'
        })
        const followerId = follower[0];
        follower = {
            username: follower[1].username,
            firstName: follower[1].firstName,
            lastName: follower[1].lastName,
            private: follower[1].private,
            profilePic: follower[1].profilePic
        }
        const followed = {
            username: user.username,
            firstName: user.first_name,
            lastName: user.last_name,
            private: user.private,
            profilePic: user.profile_image
        }
        const url = `${URL}/follow/accept/${user.id}/follower/${followerId}`;
        const res = await axios.post(url, { follower, followed });
        setConfirmed(true);
        setNotifications(notifications => notifications -1)
        return res;
    }

    const navigateToUserProfile = () => {
        ReactGA.event({
            category: 'Follow',
            action: 'User views another user after follow request'
        })
        navigate(`/${follower[1].username}`)
    }

    const requestFollow = async (newFollower) => {
        ReactGA.event({
            category: 'Follow',
            action: 'User Requests to Follow'
        })
        const followedId = newFollower[0];
        const followed = {
            username: newFollower[1].username,
            firstName: newFollower[1].firstName,
            lastName: newFollower[1].lastName,
            private: newFollower[1].private,
            profilePic: newFollower[1].profilePic
        }
        follower = {
            username: user.username,
            firstName: user.first_name,
            lastName: user.last_name,
            private: user.private,
            profilePic: user.profile_image
        }

        if (followed.private === true) {
            console.log('User private, requesting follow');
            const url = `${URL}/follow/request/${followedId}/follower/${user.id}`;
            const res = await axios.post(url, { followed, follower });
            console.log('res from follow request: ', res);
            setRequested(true);
            return res;
        } else {
            const url = `${URL}/follow/accept/${followedId}/follower/${user.id}`;
            const res = await axios.post(url, { follower, followed });
            console.log('Res from follow accept: ', res);
            setAccepted(true);
            return res;
        }
    }

    const getProfilePic = (follower) => {
        if (!!follower[1].profilePic) {
            return `url(${URL}/${follower[1].profilePic.filename})`;
        } else {
            return "none"
        }
    }

    return (
        <>
        {!declined && !confirmed && (
            <Row className='border-top'>
                <Col className='col-2 p-0 mt-1 mb-1 pl-1'>
                    <div className='feed-profile-pic-container'>
                        <div className='feed-profile-pic' onClick={navigateToUserProfile} style={{ backgroundImage: getProfilePic(follower) }} >
                        </div>
                    </div>
                </Col>
                <Col className='col-5 col-md-6 text-left p-0 mt-1'>
                    <p className='text-dark mb-1'><span className='text-primary font-weight-bold cursor-pointer' onClick={navigateToUserProfile} >{follower[1].username}</span> has requested to follow you.</p>
                </Col>
                <Col className='col-5 col-md-4 right-align d-inline mt-2 mb-1 pl-0'>
                    <Button size='sm' className='btn-primary mr-1' onClick={() => acceptFollow(follower)} >Confirm</Button>
                    <Button size='sm' className='btn-light' onClick={declineFollow} >Delete</Button>
                </Col>
            </Row>
        )}
        {!!confirmed && (!user.following || !user.following[follower[0]]) && !requested && !accepted && (
            <Row className='border-top'>
                <Col className='col-2 p-0 mt-1 mb-1 pl-1'>
                    <div className='feed-profile-pic' onClick={navigateToUserProfile} style={{ backgroundImage: getProfilePic(follower) }} >
                    </div>
                </Col>
                <Col className='col-5 col-md-6 text-left p-0 mt-1'>
                    <p className='text-dark mb-1'><span className='text-primary font-weight-bold cursor-pointer' onClick={navigateToUserProfile} >{follower[1].username}</span> is now following you.</p>
                </Col>
                <Col className='col-5 col-md-4 right-align d-inline mt-2 mb-1 pl-0'>
                    <Button size='sm' className='btn-primary mr-1' onClick={() => requestFollow(follower)} >Follow</Button>
                </Col>
            </Row>
        )}
        {!!requested && (
            <Row className='border-top'>
                <Col className='col-2 p-0 mt-1 mb-1 pl-1'>
                    <div className='feed-profile-pic' onClick={navigateToUserProfile} style={{ backgroundImage: getProfilePic(follower) }} >
                    </div>
                </Col>
                <Col className='col-5 col-md-6 text-left p-0 mt-1'>
                    <p className='text-dark mb-1'><span className='text-primary font-weight-bold cursor-pointer' onClick={navigateToUserProfile} >{follower[1].username}</span> is now following you</p>
                </Col>
                <Col className='col-5 col-md-4 right-align d-inline mt-2 mb-1 pl-0'>
                    <Button size='sm' className='btn-light mr-1'>Requested</Button>
                </Col>
            </Row>
        )}
        {((!!user.following && !!user.following[follower[0]]) || accepted) && !!confirmed && (
            <Row className='border-top'>
                <Col className='col-2 p-0 mt-1 mb-1 pl-1'>
                    <div className='feed-profile-pic' onClick={navigateToUserProfile} style={{ backgroundImage: getProfilePic(follower) }} >
                    </div>
                </Col>
                <Col className='col-5 col-md-6 text-left p-0 mt-1'>
                    <p className='text-dark mb-1'><span className='text-primary font-weight-bold cursor-pointer' onClick={navigateToUserProfile} >{follower[1].username}</span> is now following you.</p>
                </Col>
                <Col className='col-5 col-md-4 right-align d-inline mt-2 mb-1 pl-0'>
                    <Button size='sm' className='btn-primary mr-1' onClick={navigateToUserProfile} >Following</Button>
                </Col>
            </Row>
        )}
        {!!declined && (
            <Row className='border-top'>
                <Col className='col-2 p-0 mt-1 mb-1'>
                    <div className='feed-profile-pic' onClick={navigateToUserProfile} style={{ backgroundImage: getProfilePic(follower) }} >
                    </div>
                </Col>
                <Col className='col-5 col-md-6 text-left p-0 mt-1'>
                    <p className='text-dark mb-1'><span className='text-primary font-weight-bold cursor-pointer' onClick={navigateToUserProfile} >{follower[1].username}</span> has requested to follow you.</p>
                </Col>
                <Col className='col-5 col-md-4 right-align d-inline mt-2 mb-1 pl-0'>
                    <Button size='sm' className='btn-light mr-1' disabled>Declined</Button>
                </Col>
            </Row>
        )}
        </>
    )
}

export default FollowRequest;