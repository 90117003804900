import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Image, Form, Button } from 'react-bootstrap'; 
import SignUpButton from '../Auth0Login/Signupbutton';
import LoginButton from '../Auth0Login/LoginButton';
import Logo from '../../images/trottr_web_logo.svg';
import { useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import ReactGA from 'react-ga4';
import ExploreButtonTop from '../Auth0Login/ExploreButtonTop';
import BaliSwing from '../../images/bali-swing-2.png';
import ExploreButtonBottom from '../Auth0Login/ExploreButtonBottom';
import CookieConsent from 'react-cookie-consent';

const HomePage = () => {
    const { isAuthenticated } = useAuth0();
    const navigate = useNavigate();
    const [photoIndex, changePhotoIndex] = useState(0);
    const [authorized, setAuthorized] = useState(false);
    const [password, setPassword] = useState('');
    const [error, setError] = useState(false);
    
    useEffect(() => {
        if (isAuthenticated) {
            navigate("/feed");
        }
    }, [isAuthenticated])
    
    const checkPassword = (e) => {
        if (password === 'wanderlust') {
            setAuthorized(true);
        } else {
            e.preventDefault();
            setError(true);
        }
    }

    return (
        <div>
            <div className='home-page-background'>
            <div className='home-page-background-img mb-5' style={{ marginTop: "-.25rem" }}>
                <div className='position-relative pt-1 w-100'>
                <Container className='signin-container mt-2' style={{ zIndex: "1100" }}>
                     <div className='d-inline mt-4 z-3'>
                        <LoginButton />
                     </div>
                     <div className='d-inline mt-4 z-3'>
                         <SignUpButton />
                     </div>
                 </Container>
                </div>
                <div className='position-relative header-img-container mw-100'>
                    <div className='home-img-container' style={{ background: BaliSwing }}>
                        <Image className='home-img' src={BaliSwing} alt="Woman on Bali Swingset" />
                    </div>
                    <div className='home-logo-container'>
                        <Image className='filter-white' src={Logo} alt='Trottr Logo' />
                        <h1 className='text-white slogan'>Plan. Explore. Inspire.</h1>
                        <p className='product-headline'>Seamlessly plan trips with your friends while drawing inspiration from a global community of travelers.</p>
                        <div className='text-center explore'>
                                <ExploreButtonTop />
                        </div>
                    </div>
                </div>
            </div>
            <CookieConsent
                location="bottom"
                buttonText="Agree"
                style={{ background: "#101519" }}
                buttonStyle={{ background: "FF00B8", fontSize: "1.5rem" }}
            >
                This website user cookies to enhance the user experience.
            </CookieConsent>
        </div>
        </div>
    )
}

export default HomePage;