import React, { useState, useRef } from 'react';
import { Button } from 'react-bootstrap';
import { getFolders, getUserInfo } from '../apiHelpers';
import { UserState } from '../Recoil/atoms';
import { useRecoilState } from 'recoil';
import { Camera } from 'lucide-react';

const UploadButton = ({ uploadRequest, variant, setFolders }) => {
    const [uploadedFileName, setUploadedFileName] = useState(null);
    const [user, setUser] = useRecoilState(UserState);
    const inputRef = useRef(null);

    const handleUpload = () => {
        inputRef.current?.click();
    };

    const handleDisplayFileDetails = () => {
        inputRef.current?.files &&
            setUploadedFileName(inputRef.current.files[0].name);
    };


    const submitUpload = async (e) => {
        e.preventDefault();
        console.log('uploading file...');
        console.log('email: ', user);
        setUploadedFileName(null);
        const formData = new FormData();
        const imageFile = inputRef.current.files[0];
        formData.append("image", imageFile);
        uploadRequest(formData);
    }

    return (
        <div className='position-relative' style={{ height: "3rem", width: "3rem" }}>
            <form onSubmit={submitUpload}>
            <input
                ref={inputRef}
                onChange={submitUpload}
                className='d-none'
                type='file'
                name='image'
                accept='image/*'
            />
            <Button
                onClick={handleUpload}
                size="sm"
                className='btn-transparent full-center'
            >
                <Camera color='white' size={35} />
            </Button>
            {uploadedFileName && <Button className='ml-2' type='submit'>Upload</Button>}
            </form>
        </div>
    )
};

export default UploadButton;