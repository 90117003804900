import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Button } from 'react-bootstrap';
import { CALLBACK_URL } from '../../config';
import ReactGA from 'react-ga4';




const LogoutButton = () => {
  const { logout } = useAuth0();
  
  const handleLogout = () => {
    logout({ logoutParams: { returnTo: window.location.origin } });
    document.cookie = 'user' + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
    document.cookie = 'email=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
    ReactGA.event({
      category: "User Accounts",
      action: "User Logs Out"
    })
  }

  return (
    <Button
      className="btn-primary"
      size="md"
      onClick={handleLogout}
    >
      Log Out
    </Button>
  );
};

export default LogoutButton;