import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import LogoutButton from '../Auth0Login/LogoutButton';
import DeleteAccountButton from '../Auth0Login/DeleteAccountButton';
import { UserState } from '../Recoil/atoms';
import { useRecoilState } from 'recoil';
import AccountVisible from './AccountVisible';
import { useNavigate } from 'react-router-dom';
import { ChevronLeft } from 'lucide-react';

const UserAccount = ({ authTokens, setAuthTokens }) => {
    const [userInfo, setUserInfo] = useRecoilState(UserState);
    const navigate = useNavigate();



    return (
        <Container className='mt-1 pr-3 pl-3'>
            {/* <Row>
                <Col className="text-center">
                <Button className='btn-primary' onClick={handleLogout}>
                    Logout
                </Button>
                </Col>
            </Row> */}
            <Row>
                <Col className='text-left pl-2'>
                    <div className='d-inline cursor-pointer'onClick={() => navigate('/profile/share')}>
                        <ChevronLeft className='d-inline pb-1' color='#15084D' size={30} />
                        <p className='text-primary d-inline pb-0 mb-0' style={{ fontSize: "1.2rem" }}>Back</p>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col className='text-left'>
                    <h5>
                        Account Settings
                    </h5>
                </Col>
            </Row>
            <Row>
                <Col className='text-left'>
                    <p className='mb-1'>
                        <span className='font-weight-bold'>First Name: </span>{userInfo.first_name}
                    </p>
                </Col>
            </Row>
            <Row>
                <Col className='text-left'>
                    <p className='mb-1'>
                        <span className='font-weight-bold'>Last Name: </span>{userInfo.last_name}
                    </p>
                </Col>
            </Row>
            <Row>
                <Col className='text-left'>
                    <p className='mb-1'>
                        <span className='font-weight-bold'>Username: </span>{userInfo.username}
                    </p>
                </Col>
            </Row>
            <Row>
                <Col className='text-left'>
                    <p className='mb-1'>
                        <span className='font-weight-bold'>Email: </span>{userInfo.email}
                    </p>
                </Col>
            </Row>
            <AccountVisible />
            <Row className='mt-3 text-left'>
                <Col>
                    <h5>
                        Get Help or Give Feedback:
                    </h5>
                    <p>Contact <a className='text-dark' href='mailto:support@trottr.com'>support@trottr.com</a></p>
                </Col>
            </Row>
            <Row className='mt-3 text-left'>
                <Col>
                    <p className='font-weight-bold link-text cursor-pointer' onClick={() => navigate('/terms')}>Terms and Conditions</p>
                    <p className='font-weight-bold mt-1 link-text cursor-pointer' onClick={() => navigate('/privacy')} >Privacy Policy</p>
                </Col>
            </Row>
            <Row className='mt-3 mb-3'>
                <Col className="text-left">
                    <LogoutButton />
                </Col>
            </Row>
            <Row>
                <Col className='text-left'>
                    <DeleteAccountButton />
                </Col>
            </Row>
        </Container>
    )
}

export default UserAccount;