import React, { useState } from 'react';
import { Row, Col, Button, Modal, Form } from 'react-bootstrap';
import axios from 'axios';
import { Pencil } from 'lucide-react';
import { URL } from '../../config';
import { getFolders } from '../apiHelpers';


const RenameBlogModal = ({ blog, user, setFolders, insideBlog }) => {
    const [showModal, setShowModal] = useState(false);
    const [newName, setNewName] = useState('');

    const updateTripName = async (folderId, folderName, e) => {
        e.preventDefault();
        const body = {
            folderName: folderName
        }

        const url = `${URL}/users/folders/${folderId}/name`;
        const res = await axios.put(url, body);
        getFolders(user, setFolders);
        setShowModal(false);
    };

    return (
        <>
            <Pencil 
                color={insideBlog ? "lightgray" : "#424242"}
                size={25} 
                onClick={() => setShowModal(true)}
                className='cursor-pointer'
            />
            <Modal show={showModal} onHide={() => setShowModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Rename Your Memory</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={(e) => updateTripName(blog.id, newName, e)}>
                        <Row>
                            <Col>
                                <Form.Control size='md' placeholder='New name' value={newName} onChange={(e) => setNewName(e.target.value)} />
                            </Col>
                        </Row>
                        <Row>
                            <Col className='text-right mt-2'>
                                <Button variant="primary" type="submit">
                                    Create
                                </Button>
                                <Button variant="secondary ml-1" onClick={() => setShowModal(false)}>
                                    Close
                                </Button> 
                            </Col>
                        </Row>
                    </Form>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default RenameBlogModal;