import React, { useEffect, useState } from 'react';
import { Row, Col, Image, Button } from 'react-bootstrap';
import { GOOG_API_KEY } from '../../config';
import { URL } from '../../config';
import AddPhotoButton from '../Blogs/AddPhotoButton';
import Loading from '../../images/loading.gif';
import { ChevronLeft, ChevronRight } from 'lucide-react';


const ItemPhoto = ({ item, index, folderid, blogView, showModal, setShowModal, placeId, setPlace, handleShow }) => {
    const [photoIndex, setPhotoIndex] = useState(0);
    const [isVideo, setIsVideo] = useState(false);
    const [videoUrl, setVideoUrl] = useState('');
    const [loading, setLoading] = useState(false);

    const getCurrentPhoto = (photoIndex) => {
        if (!!item.user_photos && item.user_photos.length > 0) {
            const fileType = item.user_photos[photoIndex].filename.split('.')[item.user_photos[photoIndex].filename.split('.').length - 1];
            if (fileType == 'mov' || fileType == 'mp4') {
                // setIsVideo(true);
                return `${URL}/${item.user_photos[photoIndex].filename}#t=0.001`;
            } else {
                // setIsVideo(false);
                return `url(${URL}/${item.user_photos[photoIndex].filename})`;
            }
        } else {
            // setIsVideo(false);
            return `url(https://maps.googleapis.com/maps/api/place/photo?maxwidth=400&photo_reference=${item.photos[0].photo_reference}&key=${GOOG_API_KEY})`;
        }
    }

    const returnVideo = (photoIndex) => {
        if (!!isVideo) {
            return (
            <video className='feed-img' controls autoPlay muted playsInline>
                <source src={`${URL}/${item.user_photos[photoIndex].filename}`} type="video/mp4" />
                {/* <source src={getCurrentPhoto(photoIndex)} type="video/ogg" />
                <source src={getCurrentPhoto(photoIndex)} type="video/webm" /> */}
                Your browser does not support the video tag.
            </video>
            )
        }
    }

    // const handleShow = () => {
    //     ReactGA.event({
    //         category: 'Places',
    //         action: 'User Viewed Place Details'
    //     })
    //     return axios.get(`${URL}/places/details/${placeId}`)
    //     .then(({data}) => {
    //         // console.log('response: ', data.result)
    //         setPlace(data.result);
    //         return data;
    //     })
    //     .then((data) => {
    //         if (!!data.result) {
    //             setShowModal(true);
    //         } else {
    //             console.log('Error: Place not found.');
    //         }
            
    //     })
    //     .catch(err => {
    //         console.log(err)
    //     })
    // }

    useEffect(() => {
        if (!!item.user_photos && item.user_photos.length > 0) {
            const fileType = item.user_photos[photoIndex].filename.split('.')[item.user_photos[photoIndex].filename.split('.').length - 1];
            if (fileType == 'mov' || fileType == 'mp4') {
                setVideoUrl(`${URL}/${item.user_photos[photoIndex].filename}`);
                setIsVideo(true);
            } else {
                setIsVideo(false);
            }
        }
    }, [photoIndex, item])

 
    return (
        <>
            {!!item.user_photos && item.user_photos.length > 0 ? (
                <Row className="feed-img-container mr-0 ml-0 pl-0 pr-0 mb-1 mt-1 position-relative" style={{backgroundImage: "none"}}>
                    {!isVideo && <Image src={`${URL}/${item.user_photos[photoIndex].filename}`} loading='lazy' className="feed-img pl-0 pr-0 mr-0 ml-0" />}
                    {!!isVideo && (
                            <video key={videoUrl} className='feed-img' preload='metadata' controls autoPlay muted playsInline>
                                <source src={getCurrentPhoto(photoIndex)} type="video/mp4" />
                                Your browser does not support the video tag.
                            </video>
                    )}
                    {photoIndex < item.user_photos.length -1 && (
                            <ChevronRight
                                onClick={() => {
                                    setPhotoIndex(index => index + 1);
                                }}
                                size={40}
                                color='white'
                                className='right-arrow mr-2'
                                style={{ zIndex: "3" }}
                            />
                    )}
                    {photoIndex > 0 && (
                            <ChevronLeft
                                onClick={() => {
                                    setPhotoIndex(index => index - 1)
                                }}
                                size={40}
                                color='white'
                                className='left-arrow ml-2'
                                style={{ zIndex: "3"}}
                            />
                    )}
                    {loading && (
                        <div className='loading'>
                            <Image src={Loading} className='mw-100' alt='loading...' />
                        </div>
                    )}
                </Row> 
                )  : (
                <div className='pr-3 pl-3'>
                <Row onClick={(e) => handleShow()} role="button" className="item-img-container position-relative mr-0 ml-0 pl-0 pr-0 mb-1" style={{backgroundImage: getCurrentPhoto(0)}}>
                    {/* <Image src={`https://maps.googleapis.com/maps/api/place/photo?maxwidth=400&photo_reference=${item.photos[0].photo_reference}&key=${GOOG_API_KEY}`} className="item-img pl-0 pr-0 mr-0 ml-0" /> */}
                    {loading && (
                        <div className='loading'>
                            <Image src={Loading} className='mw-100' alt='loading...' />
                        </div>
                    )}
                </Row>
                </div>
            )}
            {!!blogView && (
                <Row className='mt-1 mr-0 ml-0'>
                    <Col className='text-center'>
                        <AddPhotoButton 
                            index={index} 
                            folderId={folderid} 
                            item={item} 
                            photoIndex={photoIndex} 
                            setPhotoIndex={setPhotoIndex} 
                            setLoading={setLoading}
                        />
                    </Col>
                </Row>
            )}
        </>
    )
}

export default ItemPhoto;